import React from 'react';
import '../assets/styles/home.scss';
import videoNasa1 from '../assets/video/video-nasa-1.mp4';
import videoNasa2 from '../assets/video/video-nasa-2.mp4';

const Home = () => {
  return (
    <div className='page-container'>
      <section className="section-container">
        <main className='main-container'>
          <div className='media-content'>
            <video controls>
              <source src={videoNasa1} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
          <div className='text-content'>
            <p>
              Carbon dioxide plays a crucial role in the Earth's climate system, contributing to the retention of heat in the atmosphere. This gas is released from various human activities, such as the burning of fossil fuels, and its concentrations fluctuate seasonally.
              Using data collected from NASA's Orbiting Carbon Observatory (OCO-2) satellite, scientists have developed a model that simulates the behavior of carbon in the atmosphere between September 1, 2014, and August 31, 2015.
              These models enhance our understanding of carbon dynamics and enable predictions about regions with high or low carbon dioxide concentrations based on terrestrial activities.
            </p>
          </div>
          <div className='media-content'>
            <video controls>
              <source src={videoNasa2} type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
          <div className='text-content'>
            <p>
              The oceans absorb greenhouse gases and heat from the atmosphere, mitigating the effects of human emissions of carbon dioxide. The Atlantic Meridional Overturning Circulation moves water from the Atlantic, absorbing greenhouse gases along the way and burying them deep near Greenland.
            </p>
          </div>
        </main>
        <aside className='aside-container'>
          <h1>Welcome to <span className='app-name'>Carbon Footprint</span></h1>
          <h2>What is <span className='app-name'>Carbon Footprint</span>?</h2>
          <p>
            <span className='app-name'>Carbon Footprint</span> is your resource for calculating CO2 emissions and environmental impact. We're passionate about the environment and want to help you understand the significance of your actions on our planet's health.
          </p>
          <h2>How does the application work?</h2>
          <p>
            Our application provides you with a simple and intuitive way to calculate CO2 emissions generated by various activities, such as car travel, energy consumption, and more.
          </p>
          <h2>Join the Movement</h2>
          <p>
            By understanding your carbon footprint, you take a step toward a more sustainable lifestyle. Small actions can lead to significant changes. Join us in creating a better future for our planet.
          </p>
          <p>
            We believe that understanding the impact of our actions is the first step toward a sustainable future. We're here to support you in your journey toward a more eco-friendly life. Thank you for being part of the change! 
          </p>
        </aside>
      </section>
    </div>
  );
}

export default Home;